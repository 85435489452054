import React, { useEffect, useRef } from "react";
import { TextEditorModalProps } from "../../types/modal";
import { countLines, isValidURL } from "../../utils/common-utils";
import { PiKeyReturnLight } from "react-icons/pi";
import { MdOutlineKeyboardCapslock } from "react-icons/md";

export default function TextEditorModal(props: Readonly<TextEditorModalProps>) {
  const isWarning = props.text.length >= props.characterWarningLength;
  const isCharLengthError = props.text.length === props.characterBlockLength;
  const isInvalidUrlError = !isValidURL(props.hyperlink);

  const isError = isCharLengthError || isInvalidUrlError;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  /**
   * Handle Enter key press & Ctrl + Enter key press
   * Ctrl + Enter - Save the text & add a new line
   * Enter - Save the text
   * @param e - Keyboard event
   * @returns void
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (e.ctrlKey) {
        // Allow new line when Ctrl + Enter is pressed
        e.preventDefault(); // Prevent default behavior of Enter key
        props.handleSave(); // Call the save function
      } else {
        return; // Do nothing, let the default behavior occur
      }
    } else if (e.key === "Escape") {
      props.onClose();
    }
  };

  useEffect(() => {
    if (props.open && textareaRef.current) {
      textareaRef.current.focus(); // Focus the textarea when the modal opens
    }
  }, [props.open]);

  return (
    <div
      className={`${"modal"} ${props.open ? "display-block" : "display-none"}`}
    >
      <div className="modal-main">
        <div className="modal-head">
          <h2>Update Text</h2>
          {/* Close Button */}
          <span className="modal-close-btn" onClick={props.onClose}>
            &times;
          </span>
        </div>
        <div className="modal-body d-block">
          <div className="input-field">
            <textarea
              value={props.text}
              onChange={(e) => props.onStringChange(e.target.value)}
              className={`text-field ${isCharLengthError ? "error-field" : ""}`}
              rows={Math.max(countLines(props.text) + 2, 5)}
              maxLength={props.characterBlockLength}
              onKeyDown={handleKeyDown}
              ref={textareaRef}
            />
            <div className="text-end">
              <span className={isWarning ? "warning" : isError ? "error" : ""}>
                {props.text.length}/{props.characterBlockLength - 1}
              </span>
            </div>
          </div>
          <div className="input-field mb-3">
            <label htmlFor="hyperlink-field" className="text-start w-100 mb-1">
              Hyperlink
            </label>
            <input
              type="text"
              id="hyperlink-field"
              placeholder="Leave empty to remove"
              className={`text-field hyperlink-field ${
                isInvalidUrlError ? "error-field" : ""
              }`}
              value={props.hyperlink}
              onChange={(e) => props.onHyperlinkChange(e.target.value)}
            />
          </div>
        </div>
        {!isCharLengthError && isWarning && (
          <div className="modal-error mt-1 mb-1">
            <span className="warning">
              Warning: This character count might break the design!
            </span>
          </div>
        )}
        {isCharLengthError && (
          <div className="modal-error mt-1 mb-1">
            <span className="error">Error: Character limit exceeded!</span>
          </div>
        )}
        {isInvalidUrlError && (
          <div className="modal-error mt-1 mb-1">
            <span className="error">Error: URL is invalid!</span>
          </div>
        )}
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-danger"
            onClick={props.onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={isError}
            onClick={props.handleSave}
            title="Ctrl + Enter to save"
          >
            <span className="icon-wrapper">
              <span className="btn-text">Save</span>
              <MdOutlineKeyboardCapslock />
              <PiKeyReturnLight />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
